<template>
  <div id="root">
    <el-row type="flex" align="middle" :gutter="20" style="font-size: 13px;margin-bottom: 20px;">
      <el-col :span="2">注册时间</el-col>
      <el-col :span="4">
        <el-date-picker style="width: 100%" size="mini" type="daterange" value-format="yyyyMMddHHmmssSSS" v-model="regRange"></el-date-picker>
      </el-col>
      <el-col :span="2">上线时间</el-col>
      <el-col :span="4">
        <el-date-picker style="width: 100%" size="mini" value-format="yyyyMMddHHmmssSSS" type="daterange" v-model="onLineRange"></el-date-picker>
      </el-col>
      <el-col :span="2">下线时间</el-col>
      <el-col :span="4">
        <el-date-picker style="width: 100%" size="mini" value-format="yyyyMMddHHmmssSSS" type="daterange" v-model="outLineRange"></el-date-picker>
      </el-col>
      <el-col :span="6">
        <el-button size="mini" type="primary" @click="beginQuery">查询</el-button>
        <el-button size="mini" type="danger">重置</el-button>
      </el-col>
    </el-row>

    <el-table size="mini" height="100%" :data="list" border stripe style="overflow: auto;">
      <el-table-column label="序号" width="80" type="index" sortable/>
      <el-table-column label="用户名称" width="250" prop="nickName" sortable/>
      <el-table-column label="注册时间" width="170" type="seq" sortable>
        <template slot-scope="scope">{{$util.formatTime(scope.row.createdTime)}}</template>
      </el-table-column>
      <el-table-column label="注册IP" width="120" prop="ip" sortable/>
      <el-table-column label="注册IP归属" width="150" prop="addr" sortable/>
      <el-table-column label="上次登录IP" width="120" prop="createIp" sortable/>
      <el-table-column label="上次登录IP归属" width="150" prop="createAddr" sortable/>
      <el-table-column label="最近上线时间" width="170" sortable>
        <template slot-scope="scope">{{$util.formatTime(scope.row.lastOnLineTime)}}</template>
      </el-table-column>
      <el-table-column label="最近下线时间" type="seq" width="170" sortable>
        <template slot-scope="scope">{{$util.formatTime(scope.row.lastOutLineTime)}}</template>
      </el-table-column>
      <el-table-column label="设备类型" width="100px" sortable>
        <template slot-scope="scope">{{$util.parseDeviceType(scope.row.otherInfo)}}</template>
      </el-table-column>
      <el-table-column label="登录次数" width="100px" prop="loginCount" sortable/>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "Qrcode",
  data() {
    return {
      list: [],
      regRange:[],
      onLineRange:[],
      outLineRange:[],
      queryData:{
        onLineStart:"",
        onLineEnd:"",
        regStart:"",
        regEnd:"",
        outLineStart:"",
        outLineEnd:"",
      }
    }
  },
  created() {
    this.fillData()
  },
  methods:{
    beginQuery(){
      console.log(this.regRange);
    },
    async fillData(){
      const res=await this.$http.get("/friend/list");
      this.list=res;
    }
  }
}
</script>

<style scoped>
#root {
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  background: white;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
::-webkit-scrollbar {
  width: 6px;
  height: 5px !important;
}
</style>